import React from "react"
import Layout from "../layout/layout"
import SEO from "../seo"
import { container } from "../styles/notfound.module.scss"
import { injectIntl } from "gatsby-plugin-intl"

function NotFound(props) {
  const { intl } = props
  const metaTitle = intl.formatMessage({ id: "notfound.metatitle" })
  const content = intl.formatMessage({ id: "notfound.content" })
  return (
    <Layout>
      <SEO seo={{ metaTitle: metaTitle }} />
      <div className={container}>{content}</div>
    </Layout>
  )
}

export default injectIntl(NotFound)
